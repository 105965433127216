import { ReactNode, useEffect } from 'react'
import s from './FlatTable.scss'
import cn from 'classnames'
import { TimekeeperProfileDetailsRatesAPI, TimekeeperRow } from 'timekeepers/types'
import { StatusPopover } from '../StatusPopover'
import { ChatHover } from 'simple-core-ui'
import ReactTooltip from 'react-tooltip'

interface Column {
  key: string
  title: string | ((col: Column) => ReactNode) | ReactNode
  render?: (row: TimekeeperProfileDetailsRatesAPI, rowIndex: number) => ReactNode
}

interface FlatTableProps {
  columns: Column[]
  rows: TimekeeperProfileDetailsRatesAPI[]
  className?: string
  title?: string
  actions?: ReactNode
  hasBorder?: boolean
  handleApprove: (row?: TimekeeperRow, all?: boolean) => Promise<void>
  handleReject: (row?: TimekeeperRow, all?: boolean) => void
  handleReset: (row: TimekeeperRow) => Promise<void>
}

const FlatTable = ({
  columns,
  rows,
  className,
  title,
  actions,
  hasBorder,
  handleApprove,
  handleReject,
  handleReset
}: FlatTableProps) => {
  useEffect(() => {
    ReactTooltip.rebuild()
  }, [])

  return (
    <div className={s.container}>
      <div className={s.header}>
        {title ? <p className={s.tableTitle}>{title}</p> : null}
        {actions ? <div className={s.tableActions}>{actions}</div> : null}
      </div>
      <div className={cn(s.tableContainer, { [s.tableBorder]: hasBorder })}>
        <table className={s.table}>
          <thead>
            <tr>
              {columns.map(col => (
                <th key={col.key}>
                  {typeof col.title === 'function' ? col.title(col) : col.title}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className={s.tableBody}>
            {rows.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {columns.map(col => {
                  const cellContent = row[col.key as keyof TimekeeperProfileDetailsRatesAPI]
                  return !['actions', 'reason_for_increase'].includes(col.key) ? (
                    <td
                      className={cn({
                        [s.boldText]: ['requested_rate', 'rack_rate'].includes(col.key)
                      })}
                      key={col.key}
                    >
                      {cellContent || '---'}
                    </td>
                  ) : col.key === 'reason_for_increase' ? (
                    <div className={s.chatBubble}>
                      {cellContent ? <ChatHover text={cellContent as string} /> : '---'}
                    </div>
                  ) : (
                    <StatusPopover
                      mode={row.status === 'pending' ? 'buttons' : 'dropdown'}
                      status={row.status}
                      handleApprove={handleApprove}
                      handleReject={handleReject}
                      handleReset={handleReset}
                      tableRow={row}
                    />
                  )
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default FlatTable
